// Dependency
import React from "react";
import {graphql} from "gatsby";
import Img from "gatsby-image";

// Components
import Layout from "../../components/layouts";

// Styles
import * as marqueeStyles from '../../components/marquee.module.scss'
import * as vipStyles from "./vip.module.scss";

// GraphQL to get Dato data
export const query = graphql`
    query  ($slug: String!, $locale: String!) {
        allDatoCmsVipPage(
            filter: {seoSlug: { eq: $slug },locale: {eq: $locale}}
        ){
        nodes {
            locale
            seoSlug
            title
            heroContent
            desktopHero{
                fluid(maxWidth: 1500){
                    ...GatsbyDatoCmsFluid
                }
            }
            mobileHero{
                fluid {
                    ...GatsbyDatoCmsFluid
                }
            }
        }
    }
}
`

// Template
const VipNew = (props) => {

    const pageData = props.data.allDatoCmsVipPage.nodes;

    return (
            <Layout seoData={pageData[0].seoMetaTags} country={props.pageContext.country} location={props.location}>
                <div className={vipStyles.cdVipHero}>
                    <Img alt="" fluid={pageData && pageData[0].mobileHero.fluid} />
                    <div className={vipStyles.cdVipHeroContent}>
                        <div className={`${marqueeStyles.cdMarqueeWrapper}`}>
                            <div className={`${marqueeStyles.marquee} ${vipStyles.cdMarqueeVip}`} >
                                <div className={marqueeStyles.marquee__content}>
                                    <ul className={marqueeStyles.listInline}>
                                        <li>DAY ONE</li>
                                        <li><span>DAY ONE</span></li>
                                        <li>DAY ONE</li>
                                    </ul>
                                    <ul className={marqueeStyles.listInline}>
                                        <li><span>DAY ONE</span></li>
                                        <li>DAY ONE</li>
                                        <li><span>DAY ONE</span></li>
                                    </ul>
                                    <ul className={marqueeStyles.listInline}>
                                        <li>DAY ONE</li>
                                        <li><span>DAY ONE</span></li>
                                        <li>DAY ONE</li>
                                        <li><span>DAY ONE</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={vipStyles.cdVipPage}>
                    <div className={vipStyles.cdVipBackground}>
                        <div className={"cd-background-img"}>
                            <Img alt="" fluid={pageData && pageData[0].desktopHero.fluid} />
                        </div>
                        <div className="cd-background-overlay"></div>
                    </div>
                    <div className="cd-max-width">
                        <div className={vipStyles.cdVipWrapper}>
                            <div className={vipStyles.cdVipContent}>
                                { //  is visible only if data exist
                                    pageData && pageData[0].heroContent && <div
                                        dangerouslySetInnerHTML={{__html: pageData[0].heroContent}}>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={`${marqueeStyles.cdMarqueeWrapper}`}>
                        <div className={`${marqueeStyles.marquee} ${vipStyles.cdMarqueeVip}`} >
                            <div className={marqueeStyles.marquee__content}>
                                <ul className={marqueeStyles.listInline}>
                                    <li>DAY ONE</li>
                                    <li><span>DAY ONE</span></li>
                                    <li>DAY ONE</li>
                                </ul>
                                <ul className={marqueeStyles.listInline}>
                                    <li><span>DAY ONE</span></li>
                                    <li>DAY ONE</li>
                                    <li><span>DAY ONE</span></li>
                                </ul>
                                <ul className={marqueeStyles.listInline}>
                                    <li>DAY ONE</li>
                                    <li><span>DAY ONE</span></li>
                                    <li>DAY ONE</li>
                                    <li><span>DAY ONE</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
export default VipNew;